import React from 'react';

import { css } from '@emotion/react';
import { Box, Divider } from '@mui/material';
import { graphql, HeadProps, PageProps } from 'gatsby';

import {
  Masthead,
  Bio,
  Layout,
  Seo,
  ContentWrapper,
  PostMasthead,
  Adsense,
  Disqus,
  ShareThisArticle,
  Newsletter,
} from '@/components';
import { TableOfContents } from '@/components/TableOfContents';
import { MarkdownRemark } from '@/models';
import { articleStyle } from '@/styles';

import 'katex/dist/katex.min.css';

type DataProps = {
  markdownRemark: MarkdownRemark;
};

const PostTemplate = ({ data }: PageProps<DataProps>) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Masthead background={post.frontmatter.background}>
        <PostMasthead {...post.frontmatter} timeToRead={post.timeToRead} />
      </Masthead>

      <ContentWrapper
        component={'main'}
        css={css`
          padding-top: 3rem;
          padding-bottom: 3rem;
        `}
      >
        <Box
          css={css`
            position: relative;
          `}
        >
          <TableOfContents content={post.tableOfContents} />

          <Divider />
          <Adsense />
          <Divider />

          <Box
            component="article"
            css={articleStyle}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </Box>

        <ShareThisArticle
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          content={post.rawMarkdownBody}
        />

        <Divider />
        <Adsense />
        <Divider />

        <Bio />
        <Disqus />
        <Divider />
        <Newsletter />
      </ContentWrapper>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        updatedAt(formatString: "YYYY-MM-DD")
        description
        emoji
        category
        background {
          childImageSharp {
            gatsbyImageData
          }
        }
        tags
      }
      timeToRead
      fields {
        path
      }
      tableOfContents
      rawMarkdownBody
    }
  }
`;

export const Head = ({ location, data }: HeadProps<DataProps>) => {
  const { markdownRemark: post } = data;

  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description}
      path={post.fields.path || location.pathname}
      timeToRead={post.timeToRead}
      category={post.frontmatter.category}
      date={post.frontmatter.date}
      updatedAt={post.frontmatter.updatedAt}
      ogType="article"
    />
  );
};
